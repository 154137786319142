
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './index.css'
import Home from './pages/Home';
import RegionalAnalysis from './pages/Regional Analysis';
import ChurchMinistry from './pages/Church Ministry';
import KidsandChildrenMinistry from './pages/KidsandChildrenMinistry';
import TeenandYouthMinistry from './pages/Teen and Youth Ministry';
import SeniorCitizenMinistry from './pages/Senior Citizen Ministry';
import HospitalMinistry from './pages/Hospital Ministry';
import PrisonMinistry from './pages/Prison Ministry';
import Reports from './pages/Reports';
import EventAnalysis from './pages/Event Analysis';
import Supportus from './pages/Supportus';
import Test1 from './pages/Test1';
import Test2 from './pages/Test2';



import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";




const router = createBrowserRouter([
  {
    path: "/",
    element: <App/>,
    
  },

  {
    path: "home",
    element: <Home/>,
   
  },
  {
    path: "churchministry",
    element: <ChurchMinistry/>,
  },
  {
    path: "regionalanalysis",
    element: <RegionalAnalysis/>,
  },
  {
    path: "kidsandchildrenministry",
    element: <KidsandChildrenMinistry />,
  },

  {
    path: "teenandyouthministry",
    element: <TeenandYouthMinistry/>,
  },

  {
    path: "seniorcitizenministry",
    element: <SeniorCitizenMinistry/>,
  },
  {
    path: "hospitalministry",
    element: <HospitalMinistry/>,
  },
  {
    path: "prisonministry",
    element: <PrisonMinistry/>,
  },
  {
    path: "reports",
    element: <Reports/>,
  },
  {
    path: "eventanalysis",
    element: <EventAnalysis/>,
  },
  {
    path: "supportus",
    element: <Supportus/>,
  },
  {
    path: "test1",
    element: <Test1/>,
  },
  {
    path: "test2",
    element: <Test2/>,
  },
 
]);




const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <RouterProvider router={router} />
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
