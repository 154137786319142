import React  from "react";
import Sidebar from "../components/Sidebar";
import Navbar from "../components/Navbar";
import { Navigate } from "react-router-dom";
import { getAuth } from "firebase/auth";
import Transition from "../components/Transition";
import TableauEmbed from "../TableauEmbed"
import Timeout  from "../components/Timeout";

function ChurchMinistry() {
  
  localStorage.setItem("menu", "church");




  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    return <Navigate to="/" />;
  }

 
  return (
    <>
      
      <div className="flex">
        <Sidebar />
        <div className="flex-1">
          <div>
            <Navbar title={"Church Ministry"} />
          </div>
          <Transition/>
          <div>
            <TableauEmbed/>
          </div>
        </div>
      </div>
      <Timeout />
    </>
  );
}

export default ChurchMinistry;
