import React from 'react'
import Sidebar from '../components/Sidebar';
import { Navigate} from 'react-router-dom';
// import Timeout from '../components/Timeout';
import { getAuth } from "firebase/auth";
import Transition from '../components/Transition';
import Navbar from '../components/Navbar';
import TableauEmbed from "../TableauEmbed";

function RegionalAnalysis() {

  localStorage.setItem("menu", "regional");


  //   const auth = getAuth();
  //   const user = auth.currentUser;
  // if (user) {
  //   // User is signed in
  //   const uid = user.uid;
  //   console.log(uid)
  // }else{
  //   return <Navigate to="/" />;
  // }
 
 
 
  //page clear
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    return <Navigate to="/" />;
  }
 
 
   return (
     <>
       <div className="flex ">
         <Sidebar />
         <div className="flex-1">
           <div>
             <Navbar title={"Regional Analysis"} />
           </div>
           <Transition/>
             <div className="mt-1">
               <TableauEmbed />
             </div>
         </div>
       </div>

       {/* <Timeout /> */}
     </>
   );
 }
   
  
export default RegionalAnalysis