import React, { useState, useEffect } from 'react';
import "./css/tableau.css";

const TableauViz = () => {
  
  const [message, setMessage] = useState('');
  const [tokenLoaded, setTokenLoaded] = useState(false);
  const [scriptLoaded, setScriptLoaded] = useState(false);
												   

			 

								  
																													 
										
																							 
	  





  const selected_menu = localStorage.getItem("menu");
  const selected_village = localStorage.getItem("selectedVillage");
  let src = '';

  if (selected_menu === 'hospital'){
      if(selected_village ==='Dongargaon'){
    src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Hospital_Dongargaon/Hospital_Pudumkappi';
  }else if(selected_village ==='Wankidi'){
    src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Nodataavailable/Hospital_Pudumkappi';
  }else if(selected_village ==='Chincholi'){
    src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Nodataavailable/Hospital_Pudumkappi';
  }else if(selected_village ==='Pudumkappi'){
    src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Pudumkappi_17317210750530/Hospital_Dongargaon ';
  }}
   else if (selected_menu === 'church') {
    if(selected_village ==='Dongargaon'){
    src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Dongargaon/Dongargaon';
  }else if(selected_village ==='Wankidi'){
    src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Wankidi/Church_Dongargaon';
  }else if(selected_village ==='Chincholi'){
    src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Chincholi_17315126043510/Church_Wankidi';
  }else if(selected_village ==='Pudumkappi'){
    src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Pudumkappi/Church_Chincholi';
  }}
  else if (selected_menu === 'regional') {
    if(selected_village === 'Bambara' ){
      src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/regioanal_bambara/Bambara_1?:refresh=yes';
      }else if(selected_village === 'Wankidi'){
        src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Wankidi_regional/Sondo?:refresh=yes';
      }else if(selected_village === 'Sondo'){
        src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Sondo_regional/Rebbena?:refresh=yes';
      }else if(selected_village === 'Dewada'){
        src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Dewada_regional/Dewada?:refresh=yes';
      }else if(selected_village === 'Khamana'){
        src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Khamana_regional/Bambara?:refresh=yes';
      }else if(selected_village === 'Rebbena'){
        src ='https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Rebbena_regional/Khamana?:refresh=yes';
      }else if(selected_village ==='Dongargaon'){
        src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/DONGARGAON_REGIONAL/Dongargaon';
      }else if(selected_village ==='Chincholi'){
        src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Chincholi/Dongargaon';
      }else if(selected_village ==='Pudumkappi'){
        src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/regional_PudumKappi/Chincholi';
      }
    } else if (selected_menu === 'children') {
      if(selected_village ==='Dongargaon'){
      src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Children_Dongargaon/Chincholi';
    }else if(selected_village ==='Wankidi'){
      src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Children_Wankidi/Dongargaon';
    }else if(selected_village ==='Chincholi'){
      src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/children_chincholi_17317407309200/Chincholi';
    }else if(selected_village ==='Pudumkappi'){
      src = 'https://prod-apnortheast-a.online.tableau.com/t/christfocusmission/views/Pudumkappi_Children/Wankidi';
    }}
      
    
  const fetchToken = async () => {
    try {
      const response = await fetch('/api/message', { cache: 'no-store' });
      if (!response.ok) throw new Error('Failed to fetch the token');
      const data = await response.json();
      console.log('Fetched new Token:', data.message); // Log to verify the token is fresh
      setMessage(data.message);
      setTokenLoaded(true); // Indicate token has loaded
    } catch (error) {
      console.error('Error fetching token:', error);
      setTokenLoaded(false);
    }
  };

  useEffect(() => {
    fetchToken(); // Fetch token on component mount
  }, []);

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://prod-apnortheast-a.online.tableau.com/javascripts/api/tableau.embedding.3.latest.min.js';
    script.type = 'module';
    script.onload = () => setScriptLoaded(true); // Set scriptLoaded to true when script is fully loaded
    script.onerror = () => {
      console.error('Error loading Tableau API script');
      setScriptLoaded(false);
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Cleanup on unmount
    };
  }, []);

   return (
    <div className="tableau-container">
      {tokenLoaded && scriptLoaded && src ? ( // Render only if token is loaded, script is loaded, and src is valid
        <tableau-viz 
          id="tableau-viz" 
          src={src} 
          token={message} 
          className="tableau-viz" 
          hide-tabs 
          toolbar="hidden" 
          allowfullscreen="true"
        ></tableau-viz>
      ) : (
        <p>Loading Visualization...</p>
      )}
    </div>
  );
};

export default TableauViz;
