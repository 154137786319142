import React, {useState} from 'react';
import {EyeIcon,EyeSlashIcon } from '@heroicons/react/24/solid'
import { useNavigate } from 'react-router-dom';
import{ getAuth, signInWithEmailAndPassword } from'firebase/auth';
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import '../css/Event.css'


const allowedUsers = ['cfmission@gmail.com','work@gmail.com']; // List of allowed users

function EventAnalysis() {
    const [email,setEmail]=useState('');
    const [password,setPassword]=useState('');
    const [emailError, setEmailError] = useState('');
    const navigate = useNavigate();
    // const [error, setError] = useState(null);
  
    
  
const handleSubmit = async (event) => {
  event.preventDefault();

   // Assuming allowedUsers is an object containing email-address to page mappings
   const pages = {
    'cfmission@gmail.com': '/test1',
    'work@gmail.com': '/test2',
    // Add more email-address to page mappings as needed
};

  // Assuming allowedUsers is an array containing authorized email addresses
  if (allowedUsers.includes(email)) {
      const auth = getAuth();
      try {
          const userCredential = await signInWithEmailAndPassword(auth, email, password);
          // If login successful, redirect the user to another page
          // navigate('/home');
          const user = userCredential.user;
          console.log(user);

          navigate(pages[email]);
      } catch (error) {
          setEmailError('Incorrect Username or Password');
          console.error(error);
      }
  } else {
      setEmailError("Access denied. You are not authorized to login.");
  }
};
  










  
  // password eye function
    const [showPassword, setShowPassword] = useState(false);
    
      const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
      };
   

      












    
      return (
        <>
          <div className="flex  ">
            <Sidebar />
            <div className="flex-1">
              {/* <div className="h-12 rounded-md shadow-md px-4 flex justify-center items-center"> */}
              <div>
                {/* <h1 className="text-lg font-semibold ml-3 text-[#605cb8]">Home</h1> */}
                <Navbar title={"Event Analysis"} />
              </div>
              <div>
                <div className="mt-0 ml-0">
                  <div className=" images lg:h-screen bg-full bg-no-repeat mt-1">
                    <div className="box">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                    <div className="flex justify-center items-center ">
                      {/* // login box border */}
                      <div
                        className=" log_bg w-80 p-3 pb-5 px-4 py-3 shadow-2xl bg-white bg-opacity-60 rounded-md"
                        style={{ marginTop: "5vw" }}
                      >
                        <div className="">
                          <h2
                            className="text-xl block text-center"
                            style={{ color: "#5c5ca4" }}
                          >
                            Login
                          </h2>

                          <form
                            onSubmit={handleSubmit}
                            className="mt-5 space-y-6"
                          >
                            {/* username */}
                            <div>
                              <label
                                className="text-sm"
                                style={{
                                  color: "#616d7e",
                                  fontWeight: "bold",
                                  marginBottom: "8px",
                                }}
                              >
                                Username
                              </label>
                              <input
                                type="text"
                                id="email"
                                placeholder="Enter your Username"
                                className={`${
                                  emailError && "border-red-500"
                                } w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-sm" style={{marginTop:'8px',borderColor: emailError && !email.trim() ? 'red' : '' }}`}
                                value={email}
                                onChange={(event) =>
                                  setEmail(event.target.value)
                                }
                              ></input>

                              <div
                                style={{
                                  fontSize: "1vw",
                                  visibility: "hidden",
                                }}
                              >
                                a
                              </div>

                              {emailError && (
                                <span
                                  style={{ color: "#DB4437", fontSize: "12px" }}
                                >
                                  {emailError}
                                </span>
                              )}
                            </div>

                            {/* password */}
                            <div
                              className="relative"
                              style={{ marginTop: "0.5vw" }}
                            >
                              <label
                                className="text-sm "
                                style={{
                                  color: "#616d7e",
                                  fontWeight: "bold",
                                  marginBottom: "8px",
                                  marginTop: "5px",
                                }}
                              >
                                Password
                              </label>
                              <input
                                type={showPassword ? "text" : "password"}
                                id="password"
                                placeholder="Enter your Password"
                                className={` ${
                                  emailError && "border-red-500"
                                }  w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 text-sm" style={{marginTop:'8px',borderColor: emailError && !email.trim() ? 'red' : '' }}`}
                                value={password}
                                onChange={(event) =>
                                  setPassword(event.target.value)
                                }
                              />

                              {/* {passwordError && <span style={{ color: '#DB4437', fontSize:'1.2vw'}}>{passwordError}</span>} */}

                              {/* password eye showin and showout */}
                              <i
                                type="button"
                                className=" absolute inset-y-0 right-0 px-4 py-9 text-gray-600 focus:outline-none  cursor-pointer"
                                onClick={togglePasswordVisibility}
                              >
                                {showPassword ? (
                                  <EyeIcon className="h-5 w-5" />
                                ) : (
                                  <EyeSlashIcon className="h-5 w-5" />
                                )}
                              </i>
                            </div>

                            {/* login button */}
                            <div mt-5>
                              <button
                                type="submit"
                                onClick={handleSubmit}
                                className="justify-center relative flex w-full py-1 px-4 bg-blue-500 hover:bg-blue-700  border border-transparent text-white  rounded-full"
                              >
                                Login
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }
    
    
export default EventAnalysis   