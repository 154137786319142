
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// cloud firestore
// import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCECTSFc06TiS_OX9wITSIP4PRlvex6lgk",
  authDomain: "data-for-christ.firebaseapp.com",
  projectId: "data-for-christ",
  storageBucket: "data-for-christ.appspot.com",
  messagingSenderId: "872166505393",
  appId: "1:872166505393:web:89a88c3e1cef1873679b90"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
//cloud Firebase 
// export const dp = getFirestore(app);
