import React, { useEffect, useState } from 'react';
import video_mp from '../videos/video3.mp4';

// Define the specific transition durations for each route
const pageDurations = {
 '/regionalanalysis':5000,
  '/churchministry': 5000,  
  '/hospitalministry': 5000,
  '/kidsandchildrenministry': 6000, 
  '/teenandyouthministry': 3000, 
  '/seniorcitizenministry': 3000, 
  '/prisonministry': 3000, 
  '/reports': 2000, 
  '/default': 7000 // Default duration if route is not specified
};

function Transition() {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const currentPath = window.location.pathname;
    const duration = pageDurations[currentPath] || pageDurations['/default'];

    const timer = setTimeout(() => setIsVisible(false), duration);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {isVisible && (
        <video
          src={video_mp}
          autoPlay
          loop
          muted
          className="bg-cover w-full h-auto object-cover md:h-full absolute z-10"
        />
      )}
    </div>
  );
}

export default Transition;
