import React, { useEffect, useState } from 'react';
import Sidebar from '../components/Sidebar';
import { Navigate } from 'react-router-dom';
import { getAuth } from "firebase/auth";
import Transition from '../components/Transition';
import Navbar from '../components/Navbar';
import ReactPlayer from 'react-player';
import "../css/Report.css";

function Reports() {
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  localStorage.setItem("menu", "report");


  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(currentUser => {
      setUser(currentUser);
      setLoading(false);
    });

    return () => unsubscribe();  // Cleanup on unmount
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/" />;
  }
  const selected_menu = localStorage.getItem("menu");
  const selected_village = localStorage.getItem("selectedVillage");
  let url = '';

   if (selected_menu === 'report') {
        if(selected_village === 'Chincholi' ){
        url = "https://dataforchrist29.wistia.com/medias/3fsk9l28ut";
        }else if(selected_village === 'Wankidi'){
          url = "https://dataforchrist29.wistia.com/medias/38eqr8snuz";
        }else if(selected_village === 'Pudumkappi'){
          url = "https://dataforchrist29.wistia.com/medias/sqfisd1e6b";
        }else if(selected_village ==='Dongargaon'){
          url = "https://dataforchrist29.wistia.com/medias/2eh4xcmr8z";
        }
    }  

  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className="flex-1" style={{ backgroundColor: 'black', minHeight: '100vh' }}>
          <div className='bg-white'>
            <Navbar  title={"Outreach Analysis"}  />
          </div>
          <Transition />

          <div className="flex">
            <div className="video-container">
              <ReactPlayer
                url={url}
                width="90%"
                height="90%" // Corrected height
                controls
                autoPlay
                loop
                //muted
                className="videos"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Reports;
