import {React,useEffect,useState} from 'react'
import Logout from '../pages/Logout';


function Timeout() {
    const [isActive, setIsActive] = useState(true);

  const timeout_duration=900000;//15min

  useEffect(() => {
    let timeout;
    const resetTimeout = () => {
      clearTimeout(timeout);
     timeout = setTimeout(() => {
      setIsActive(false);
      // localStorage.removeItem('token');
      <Logout />
      // Redirect to login page after 10 seconds
     window.location.href = '/';
     },timeout_duration);
    };
    const events = ['mousemove', 'mousedown', 'keydown', 'touchstart','mousewheel'];
    events.forEach(event => {
      window.addEventListener(event, resetTimeout);
    });
    return () => {
      clearTimeout(timeout);
      events.forEach(event => {
        window.removeEventListener(event, resetTimeout);
     })} })

  return (
    <div>
        {isActive ? (
          <div></div>
        ) : (
          <div className='text-center'>User timed out. Redirecting...</div>
          // You can render a redirect component or log out the user here
        )}
      </div>
  )
}

export default Timeout