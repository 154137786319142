import React, { useState, useEffect, useRef } from "react";
import { Bars3Icon } from '@heroicons/react/24/solid';
import { Link, useLocation } from 'react-router-dom';
import Logout from '../pages/Logout';
import { AiFillHome } from "react-icons/ai";
import { FaMapLocationDot, FaChildren, FaHospital, FaChurch } from "react-icons/fa6";
import { MdElderlyWoman } from "react-icons/md";//add MdEvent
import { GiHumanPyramid, GiPrisoner } from "react-icons/gi";
import { IoMdAnalytics } from "react-icons/io";
import { GiReceiveMoney } from "react-icons/gi";
import { Tooltip } from 'react-tippy';
import 'react-tippy/dist/tippy.css';
import '../css/Sidebar.css';

const Sidebar = ({ selectedCountry, selectedState, selectedDistrict, selectedVillage }) => {//add
  const menus = [
    { name: "Home", link: "/home", icon: AiFillHome },
    { name: "Regional Analysis", link: "/regionalanalysis", icon: FaMapLocationDot },
    { name: "Church Ministry", link: "/churchministry", icon: FaChurch },
    { name: "Hospital Ministry", link: "/hospitalministry", icon: FaHospital },
    { name: "Children Ministry", link: "/kidsandchildrenministry", icon: FaChildren },
    { name: "Youth Ministry", link: "/teenandyouthministry", icon: GiHumanPyramid },
    { name: "Sr Citizen Ministry", link: "/seniorcitizenministry", icon: MdElderlyWoman },
    { name: "Prison Ministry", link: "/prisonministry", icon: GiPrisoner },
    { name: "Outreach Analysis", link: "/reports", icon: IoMdAnalytics },
    { name: "Support Us", link: "/supportus", icon: GiReceiveMoney },
    // { name: "Event Analysis", link: "/eventanalysis", icon: MdEvent },


  ];

  const [expanded, setExpanded] = useState(true);
  const location = useLocation();
  const [activePage, setActivePage] = useState("");
  const sidebarRef = useRef(null);


  useEffect(() => {
    setActivePage(location.pathname);
  }, [location.pathname]);


  // selection alert 
  const handleItemClick = (link) => {
    
    console.log("Country:", selectedCountry);
    console.log("State:", selectedState);
    console.log("District:", selectedDistrict);
    console.log("Village:", selectedVillage);
  
    // Automatically activate "Event Analysis" link
    if ( link === "/home") {
      // Always allow access to Event Analysis and Home
      setActivePage(link);
    }else if (localStorage.getItem("selectedCountry")) {
      alert("Please select a Country,State,District and Village");
    } else if (!localStorage.getItem("selectedState")) {
      alert("Please select a State,District and Village");
    } else if (!localStorage.getItem("selectedDistrict")) {
      alert("Please select a District and Village");
    } else if (!localStorage.getItem("selectedVillage")) {
      alert("Please select a Village");
    }else {
      // Navigate if all selections are made
      setActivePage(link);
    }

  };
  
  // Minimize sidebar when mouse moves outside
  useEffect(() => {
    const handleMouseLeave = (e) => {
      if (sidebarRef.current && !sidebarRef.current.contains(e.target)) {
        setExpanded(true);
      }
    };

    document.addEventListener("mousemove", handleMouseLeave);
    return () => {
      document.removeEventListener("mousemove", handleMouseLeave);
    };
  }, []);
// store the state dist village in var
  const homePageSelection =selectedCountry && selectedState && selectedDistrict && selectedVillage;
    localStorage.setItem('homePageSelection',homePageSelection);

  return (
    <div className="m-0 p-0">
      <aside
        ref={sidebarRef}
        className="sidebar-container"
      >
        <div
          style={{ paddingBottom: "3rem" }}
          className={`${
            expanded ? "w-12 " : "w-36"
          } sidebar-expand `}
        >
          <div>
            <h1
              className={`text-sm text-white ml-4 fixed flex mt-2 ${
                expanded ? "hidden" : !"hidden"
              }`}
            >
              Data For Christ
              <sup
                style={{
                  fontSize: "4px",
                  verticalAlign: "super",
                  paddingLeft: "1px",
                }}
              >
                TM
              </sup>
            </h1>

            <button
              style={{ marginLeft: !expanded ? "" : "5px" }}
              onClick={() => setExpanded((curr) => !curr)}
              className="cursor-pointer text-white mt-2"
            >
              {expanded && <Bars3Icon className="w-6 h-6" />}
            </button>

            <div className="p-2 pb-2 border-b flex -mt-3 items-center"></div>
          </div>

          <div className="mt-3 flex flex-col gap-2 relative">
            {menus?.map((menu, index) => {
              const isActive = activePage === menu.link;
              // Select village state disable
              const isDisabled = (!localStorage.getItem("homePageSelection"));
              // route for event and home
              const shouldDisableLink = isDisabled && menu.link !== "/home";

              const linkClassName = `hover:bg-[#5caca4] hover:text-black left-24 ${
                menu.margin ? " mt-3" : ""
              } group flex items-center w-7 text-white text-xs gap-3 font-medium p-1 rounded-md ${
                isActive ? "bg-[#fcac1c]" : ""
              }`;

              return (
                <Link
                to={!shouldDisableLink ? menu.link : "#"}
                key={index}
                  onClick={() => handleItemClick(menu.link)}
                  className={linkClassName}
                  // disabled={disabled}
                >
                  <div>
                    {React.createElement(menu?.icon, {
                      style: { fontSize: "1.5em" },
                    })}
                  </div>
                  <h2
                    style={{
                      transitionDelay: `${index + 1}00ms`,
                    }}
                    className={`whitespace-pre duration-300 ${
                      expanded && "opacity-0 translate-x-28 overflow-hidden"
                    }`}
                  >
                    {menu?.name}
                  </h2>
                  <Tooltip
                    title={menu?.name}
                    position="right"
                    trigger="mouseenter"
                    arrow={true}
                    duration={300}
                    hideDelay={0}
                    animateFill={false}
                    animation="fade"
                    disabled={!expanded}
                    className="absolute font-sm whitespace-pre text-center drop-shadow-lg px-4 py-3"
                  ></Tooltip>
                </Link>
              );
            })}

<div className="flex">
            <div
              className={` left-0 flex-1 p-3 bottom-0 duration-500  ${
                !expanded ? "w-36" : "w-12"
              }`}
            >
              <Logout />
            </div>
          </div>
          </div>

         
        </div>
      </aside>
    </div>
  );
};

export default Sidebar;