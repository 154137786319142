import React from 'react';
import { useNavigate } from 'react-router-dom';
import { IoMdLogOut } from "react-icons/io";
import { Tooltip } from 'react-tooltip'; // Import Tooltip correctly

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    const confirmLogout = window.confirm('Are you sure you want to logout?');
    if (confirmLogout) {
      localStorage.clear();
      sessionStorage.clear();
      navigate('/');
    }
  };

  return (
    <>
    <div>
      <button data-tooltip-id="logout-tooltip" onClick={handleLogout} className="text-white hover:text-red-700">
        <IoMdLogOut style={{ width: '22px', height: '22px', marginLeft: '-8px' }} />
      </button>
      </div>
      <Tooltip id="logout-tooltip" place="right" content="Logout" className='z-10  absolute' />
    </>
  );
};

export default Logout;
