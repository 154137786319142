import React, { useState, useEffect } from 'react';




  const Navbar = (props) => {
    const [title, setTitle] = useState('');




    useEffect(() =>{
        setTitle(props.title);
    },[props])

   



    return (
      <>
        <div className="h-12 rounded-md shadow-md px-2 flex justify-left items-left ">
          <div className=" text-lg font-semibold ml-2 text-[#605cb8] mt-2">
            {title}
          </div>
          <div
            className="flex items-center ml-14"
            style={{ paddingLeft: "3rem" }}
          ></div>
          <div
            class="bg-[url('./image/cfm6.svg')] top-0 right-1  h-0 w-0 bg-no-repeat absolute"
            style={{ height: "40px", width: "40px", marginTop: "6px" }}
          ></div>
        </div>
      </>
    );

}


export default Navbar
