import React from 'react'
import Sidebar from '../components/Sidebar';
import { Navigate} from 'react-router-dom';
import Timeout from '../components/Timeout';
import { getAuth } from "firebase/auth";
import Transition from '../components/Transition';
import Navbar from '../components/Navbar';
// import TableauEmbed from "../TableauEmbed"
import dataNotAvailable from '../image/data3.png';
import '../css/Checkback.css';

function SeniorCitizenMinistry() {
  localStorage.setItem("menu", "elderly");

//   //  Initialize Firebase
//    const auth = getAuth();
//    const user = auth.currentUser;
//  if (user) {
//    // User is signed in
//    const uid = user.uid;
//    console.log(uid)
//  }else{
//    return <Navigate to="/" />;
//  }


  //page clear
  const auth = getAuth();
  const user = auth.currentUser;
  if (!user) {
    return <Navigate to="/" />;
  }




  return (
    <>
      <div className="flex ">
        <Sidebar />

        <div className="flex-1">
          {/* <div className="h-12 rounded-md shadow-md px-4 flex justify-center items-center"> */}
          <div>
            {/* <h1 className="text-lg font-semibold ml-3 text-[#605cb8]">Home</h1> */}
            <Navbar title={"Sr Citizen Ministry"} />
          </div>
          <Transition />
          <div className="mt-1">
          <img src = {dataNotAvailable } alt='' className='check-back' />
          {/* <TableauEmbed /> */}
          </div>
        </div>
      </div>

      <Timeout />
    </>
  );
}

export default SeniorCitizenMinistry