import React from 'react'
import Sidebar from '../components/Sidebar';


function Test1() {
  return (
    <>
      <div className="flex">
        <Sidebar />
        <div className=" rounded-md shadow-md mt-1 p-1 w-screen  h-12 ">
        <div class="bg-[url('./image/cfms12.svg')] absolute top-0 right-1 mt-0.5 h-0 w-0 bg-no-repeat " style={{height: '50px' ,width: '55px'}}></div>
          <h1 className="text-lg font-semibold ml-3  text-[#0b0a0f]">
           Test1
           </h1>
           </div>
      </div>
    </>
  );
}

export default Test1