import React from 'react'
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Transition from '../components/Transition';

const Supportus = () => {
  return (
   <>
 <div className="flex">
        <Sidebar />
        <div className="flex-1">
          <div>
            <Navbar title={"Support Us"} />
          </div>
          <Transition/>
          <div>
          <div style={{ width: '100%', height: '100vh', overflow: 'hidden' }}>
      <iframe
        src="https://pages.razorpay.com/cfmoutreach001"
        title="Razorpay Page"
        style={{
          width: '100%',
          height: '100%',
          border: 'none',
        }}
        allowFullScreen
      />
    </div>
          </div>
        </div>
      </div>
      {/* <Timeout /> */}
    </>
  )
}

export default Supportus